html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.modal-dialog.ModalBody {
  width: 70%;
  margin-top: 6em;
  overflow: scroll;
  max-width: 100%;
}
.modal-content {
  width: 50% !important;
  margin: auto !important;
}

.modal-dialog.ModalBodyEmpty {
  width: 40%;
  margin-top: 6em;
  overflow: scroll;
  max-width: 100%;
}

.ModalBody .modal-content {
  border: none;
  border-radius: 0;
}

.MuiDialog-paperWidthLg {
  background: transparent !important;
}

.MuiPaper-elevation24 {
  box-shadow: none !important;
}
.alertBox {
  color: #fff !important;
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
  width: 20%;
  display: flex !important;
  align-items: center;
  margin-left: auto;
  position: absolute !important;
  right: 22px;
  top: 46px;
  z-index: 9999999;
}

.alertBoxError {
  color: #842029 !important;
  background-color: #f8d7da !important;
  border-color: #f5c2c7 !important;
}

.alertBox svg {
  font-size: 23px;
  margin-right: 12px;
}

.alertBox button {
  background: none;
  outline: none;
  border: none;
  color: #fff;
  position: absolute;
  right: 0;
  margin-right: 10px;
}
.paginations {
  border-top: 1px solid #dedede;
  font-family: 'Open Sans', sans-serif;
  border-right: 1px solid #dedede;
  border-left: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
}
.alertBox button span {
  font-size: 29px;
}

.description {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  color: #000000 !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  min-height: 60px;
}
.itemName {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  color: #000000 !important;
  font-weight: 600 !important;
  font-size: 15px !important;
}
.price {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  color: #469ed4 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}
.cards {
  overflow: hidden;
  border-radius: 10px;
}
.singleCard {
  width: 100%;
}

.MuiCardMedia-root {
  margin: 10px auto !important;
  border-bottom: none !important;
  max-width: 62% !important;
}
.card {
  display: 'flex';
  flex-wrap: 'wrap';
  height: '450px';
  overflow: 'auto';
}
/* .makeStyles-cardTitle-19{
  border-top:1px solid #dedede;
} */

/* .MuiDialogContent-root{
  overflow-y:hidden !important;
} */
.makeStyles-image-34.makeStyles-noImageFound-21 {
  border-bottom: none;
}
.bg-white {
  background: #fff;
}
.custombutton {
  margin-bottom: 6px !important;
  color: #ffffff;
  background: #66ccff;
  font-size: 14px;
  height: 34px;
  font-weight: 400;
  border-radius: 4px;
  text-transform: none;
  border: none;
  outline: none;
  margin: auto;
}
.logoclass {
  height: 40px;
  padding: 4px 10px;
  max-width: 100%;
  margin: 5px auto;
  outline: none;
  display: block !important;
}
.vendorModal.modal-dialog.vendorModal {
  max-width: 100% !important;
}
.MuiCardMedia-root {
  background-size: contain !important;
  height: 181px !important;
}

.makeStyles-noImageFound-32 {
  margin: 10px auto !important;
  border-bottom: none !important;
}
.MuiDialogContent-dividers {
  border-bottom: none !important;
  /* overflow: hidden !important; */
}
.MuiMenuItem-root {
  display: flex !important;
  align-items: center;
  justify-content: flex-start !important;
  padding: 12px !important;
}
.vendorCard {
  width: 33%;
  padding: 20px;
  max-width: 100%;
}
.vendorCardNew {
  width: 50%;
  padding: 20px;
  max-width: 100%;
}
.singleVendorCard {
  width: 100%;
  padding: 20px;
  max-width: 100%;
}
img.vendorImage {
  width: auto !important;
}
.VendorName {
  font-size: 12px;
  height: 40px;
  padding: 4px 10px;
  max-width: 100%;
  margin: 0 auto;
  outline: none;
  display: block !important;
}
.vendorDescription {
  display: -webkit-box;
  color: #000000 !important;
  font-weight: 600 !important;
  font-size: 11px !important;
  line-height: 16px;
}
.cardImage {
  border-bottom: 1px solid #dedede !important;
}
.Card {
  display: flex;
  flex-wrap: wrap;
  height: 450px;
  overflow-y: auto;
}
.single {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}
.twoCard {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}
.twoCards {
  padding: 20px;
  width: 50%;
}
.twoCards > div,
.vendorCard > div,
.singleVendorCard > div,
.notFound {
  width: 250px;
  margin: 0 auto;
}
.noImage {
}
#dialogBox {
  width: 950px;
  display: block;
  max-width: 100%;
  margin: auto;
  margin-top: 10px;
}

#advanced-search .MuiDialogContent-dividers {
  padding: 0%;
  background: #fff;
}
#dialogBox .MuiDialogContent-root {
  overflow: hidden;
  padding: unset;
}
#dialogBox .MuiDialogTitle-root,
.MuiDialogActions-root {
  display: none !important;
}
.MuiCardActions-root {
  display: flex !important;
  justify-content: center !important;
}
.singleCards {
  width: 365px;
}
@media only screen and (min-width: 769px) and (max-width: 900px) {
  .Card {
    height: 265px;
    width: 590px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
  .Card {
    /* height: 265px;  */
    width: 590px;
  }
  .twoCard {
    /* height:265px; */
  }
  .single {
    height: unset;
    overflow: hidden;
  }
  .vendorCard {
    width: unset;
  }
  #dialogBox .MuiDialogContent-root {
    overflow: auto;
    background-color: #fff;
    padding: 0px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 767px) {
  .singleCards {
    /* height: 267px;  */
    display: block;
  }

  .twoCard {
    /* height:267px; */
    display: block;
    border-bottom: 5px solid #fff;
  }
}

@media screen and (max-width: 767px) {
  .twoCards {
    width: 100%;
    overflow: hidden;
  }

  #dialogBox .MuiDialogContent-root {
    overflow: auto;
    background-color: #fff;
    padding: 0px;
  }
  .Card {
    display: block;
    height: 267px;
  }
}
@media screen and (max-width: 600px) {
  .description {
    height: unset;
  }
  .twoCard {
    height: 350px !important;
    overflow: auto;
  }
  .modal-dialog.ModalBody {
    padding-top: 6em;
    margin: auto;
  }
  .singleCards {
    width: 100%;
  }
  .Card {
    height: unset;
  }
}

@media only screen and (min-width: 560px) and (max-width: 570px) {
  .twoCard {
    height: unset !important;
  }
}
@media screen and (max-width: 320px) {
  .Card {
    height: unset;
  }

  .twoCards > div,
  .vendorCard > div,
  .singleVendorCard > div,
  .notFound {
    width: 215px;
    margin: 0 auto;
  }
}
